const clouds = [
    {
        path: '/:project_id/clouds',
        name: 'clouds.index',
        component: () => import('@/pages/Cloud/VPS/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            need_project: true,
            layout: 'content',
            title: "VPS sotib olish",
        }
    },
    {
        path: '/:project_id/clouds/vps',
        name: 'clouds.vps.index',
        component: () => import('@/pages/Cloud/VPS/IndexPage.vue'),
        meta: {
            requiresAuth: true,
            need_project: true,
            layout: 'content',
            title: "VPS sotib olish",
        }
    },
    {
        path: '/:project_id/clouds/volumes',
        name: 'clouds.volumes',
        component: () => import('@/pages/Page/PageSoon.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/:project_id/vpc/load-balancers',
        name: 'vpc.load_balancers',
        component: () => import('@/pages/Cloud/LoadBalancer/IndexPage.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Сеть",
        }
    },
    {
        path: '/:project_id/clouds/networks/:tab',
        name: 'clouds.networks',
        component: () => import('@/pages/Cloud/Network/IndexPage.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Сеть",
        }
    },
    {
        path: '/:project_id/clouds/firewalls',
        name: 'clouds.firewalls',
        component: () => import('@/pages/Cloud/Firewall/IndexPage.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Firewalls",
        }
    },
    {
        path: '/:project_id/clouds/firewalls/create',
        name: 'clouds.firewalls.create',
        component: () => import('@/pages/Cloud/Firewall/CreatePage.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Firewalls",
        }
    },
    {
        path: '/:project_id/clouds/firewalls/:id/edit',
        name: 'clouds.firewalls.edit',
        component: () => import('@/pages/Cloud/Firewall/EditPage.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Firewalls",
        }
    },
    {
        path: '/:project_id/clouds/images',
        name: 'clouds.images',
        component: () => import('@/pages/Page/PageSoon.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/:project_id/clouds/disks',
        name: 'clouds.disks',
        component: () => import('@/pages/Cloud/Disk/Index.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Диски",
        }
    },
    {
        path: '/:project_id/clouds/backups',
        name: 'clouds.backups',
        component: () => import('@/pages/Page/PageSoon.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/:project_id/clouds/file-storages',
        name: 'clouds.file_storages',
        component: () => import('@/pages/Page/PageSoon.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    },
    {
        path: '/:project_id/clouds/load-balancers',
        name: 'clouds.load_balancers',
        component: () => import('@/pages/Page/PageSoon.vue'),
        meta: {
            need_project: true,
            requiresAuth: true,
            layout: 'content',
            title: "Profil sozlamalari",
        }
    }
]

export default clouds